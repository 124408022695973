/******* screen-medium.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* layout-medium.less 2013-1-16 *******/
#head {
  width: 90%;
  margin-right: 5%;
  margin-left: 5%;
}
.cb-mobile-navigation-items {
  padding-right: 3%;
  padding-left: 3%;
  width: 94%;
}
#home {
  margin-right: 3%;
}
#contact {
  padding: 3%;
}
#content {
  padding-bottom: 245px;
}
#fontsize {
  display: block;
  margin-right: 5%;
}
#contactfix {
  position: absolute;
  bottom: 0;
  left: 0;
}
.cb-album.cb-manual .head .ctrl .prev {
  left: 2%;
}
.cb-album.cb-manual .head .ctrl .next {
  right: 2%;
}
.vcard.mobile {
  display: block;
  width: 100%;
  float: left;
}
.vcard.mobile .camphill {
  background: url(/images/camphill-white.svg) no-repeat 0 0.2em;
  background-size: 30px;
  padding-top: 0;
  text-indent: 35px;
}
.vcard.mobile .adr {
  margin-top: 0;
}
.vcard.mobile .humanushaus-hours {
  margin-top: 0;
}
.vcardwidth {
  float: left;
  width: 30%;
  text-align: left;
  max-width: 10000px;
  margin-right: 3.33333333%;
}
#maincontent {
  width: 100%;
  margin: 0;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 2%;
  margin-left: 2%;
}
.area .part,
.area > .grid table {
  margin-right: 2%;
  margin-left: 2%;
  width: 96%;
}
.area .tiny {
  width: 46%;
}
.area > .slim {
  width: 50%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 4%;
  margin-left: 4%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 92%;
}
.area > .slim .tiny {
  width: 92%;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
.main {
  width: 94%;
  margin-left: 3%;
  margin-right: 3%;
}
div.area div.seam,
div.area div.flat {
  margin-left: 2%;
  margin-right: 2%;
}
div.area div.seam.wide,
div.area div.flat.wide {
  width: 96%;
}
div.area div.seam.wide .part.tall,
div.area div.flat.wide .part.tall {
  width: 96%;
}
div.area div.seam.wide .part.tiny,
div.area div.flat.wide .part.tiny {
  width: 46%;
}
div.area div.seam.slim,
div.area div.flat.slim {
  width: 46%;
}
div.area div.seam.slim .part.tall,
div.area div.flat.slim .part.tall {
  width: 92%;
}
div.area div.seam.slim .part.tiny,
div.area div.flat.slim .part.tiny {
  width: 42%;
}
#maincontent .flat,
#maincontent .seam {
  min-height: 160px;
}
#maincontent div.flat:before {
  content: ' ';
  width: 19px;
  height: 38px;
  display: block;
  position: absolute;
  left: -19px;
  top: 26px;
}
.c1 #maincontent div.flat:before {
  background: url(/images/corner-c1.png) no-repeat 0 0;
}
.c2 #maincontent div.flat:before {
  background: url(/images/corner-c2.png) no-repeat 0 0;
}
.c3 #maincontent div.flat:before {
  background: url(/images/corner-c3.png) no-repeat 0 0;
}
.c4 #maincontent div.flat:before {
  background: url(/images/corner-c4.png) no-repeat 0 0;
}
.c5 #maincontent div.flat:before {
  background: url(/images/corner-c5.png) no-repeat 0 0;
}
.c6 #maincontent div.flat:before {
  background: url(/images/corner-c6.png) no-repeat 0 0;
}
.c7 #maincontent div.flat:before {
  background: url(/images/corner-c7.png) no-repeat 0 0;
}
div.main div.cb-album {
  width: 96%;
  margin-left: 2%;
  margin-right: 2%;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 10% !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
#disp.shop,
#disp.cb-shoppingcart {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
h1,
h2 {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/*# sourceMappingURL=./screen-medium.css.map */